
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import { assigneeOptions, boardOptions } from '@/definitions/_general/_data/optionsList'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import { taskStatuses } from '@/definitions/tasks/data'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
    TmEmoji,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const title = ref()

    const message = ref(props.description)

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.ai,
    ]

    const assignTo = ref(assigneeOptions[3])

    const board = ref(boardOptions[0])

    const priorityOptions = ref(priorityVariant)
    const priorityValue = ref(priorityOptions.value[0])

    const stage = ref(taskStatuses[0])

    const date = ref(new Date('2022-02-17T00:00:00'))

    return {
      isSmMax,
      date,
      assignTo,
      board,
      boardOptions,
      taskStatuses,
      priorityOptions,
      priorityValue,
      assigneeOptions,
      stage,
      title,
      message,
      btnsList,
    }
  },
})
